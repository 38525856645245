/*
* Crook -Responsive One Page  & multipage Parallax Template For landing, agency, portfolio, webStudio
* Build Date: apr 2020
* Author: creative minds
* Copyright (C) 2020 creative minds
*/
/* ------------------------------------- */
/* *. main files .......................... */
/* ------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@500;600;700&family=Roboto:wght@400;500;700&display=swap");
html {
  height: 100%;
  margin: 0;
  overflow-y: scroll;
}

body {
  font-size: 14px;
  line-height: 1.7;
  font-weight: 400;
  overflow-x: hidden !important;
  font-family: "Roboto", sans-serif;
  color: #9b9b9b;
  background: #ffffff;
  padding: 0;
  margin: 0;
  outline: 0;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Barlow", sans-serif;
  color: #1b1a1a;
  text-transform: capitalize;
  clear: both;
  line-height: 1.2;
  margin: 0 0 15px;
  font-weight: 700;
}

h1, .h1 {
  font-size: 52px;
  line-height: 1.2;
}

@media only screen and (max-width: 992px) {
  h1, .h1 {
    font-size: 38px;
    line-height: 1.3667em;
  }
}

h2, .h2 {
  font-size: 42px;
  line-height: 1.4;
}

@media only screen and (max-width: 992px) {
  h2, .h2 {
    font-size: 28px;
    line-height: 1.4286em;
  }
}

h3, .h3 {
  font-size: 36px;
  line-height: 1.5;
}

h4, .h4 {
  font-size: 32px;
  line-height: 1.7;
}

h5, .h5 {
  font-size: 26px;
  line-height: 1.7;
}

h6, .h6 {
  font-size: 18px;
  line-height: 1.8;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
}

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
  color: #e9204f;
}

a, p a {
  color: #e9204f;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.text-small {
  font-size: 10.50px;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

ol, ul {
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

ol ol, ol ul, ul ol, ul ul {
  margin: 0;
  padding: 0 0 0 15px;
}

pre {
  background-color: #ffffff;
  padding: 15px;
  max-width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 15px 0;
}

dt {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}

dd {
  margin-bottom: 15px;
}

sub, sup {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  top: .5ex;
}

sup {
  bottom: .5ex;
}

.wp-caption-text {
  margin: 0;
  font-style: italic;
}

table {
  width: 100%;
}

table tbody tr, table thead tr {
  border: 1px solid #efefef;
}

table tbody th {
  border-right: 1px solid #efefef;
}

img {
  max-width: 100%;
}

blockquote {
  color: #1b1a1a;
  font-size: 18px;
  padding: 0 28px;
  border-left: 2px solid #e9204f;
  font-style: italic;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

blockquote p {
  font-family: "Roboto", sans-serif;
}

@media only screen and (max-width: 992px) {
  blockquote {
    font-size: 16px;
    line-height: 1.8;
    padding: 0 9px;
  }
}

blockquote cite {
  font-style: normal;
  font-weight: 500;
}

::selection {
  background: #e9204f;
  color: #ffffff;
}

::-moz-selection {
  background: #e9204f;
  color: #ffffff;
}

.fa, .fas {
  font-weight: 900 !important;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #131313;
  z-index: 999999;
}

.loader .loader-inner {
  position: absolute;
  border: 2px solid #e9204f;
  border-left-color: transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  -webkit-animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

img {
  max-width: 100%;
}

.complex-img {
  position: relative;
  padding: 0 40px 60px;
}

.complex-img .img-one {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 500px;
  min-height: 500px;
  border-radius: 4px;
}

.complex-img .img-two {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  width: calc(100% - 75px);
  max-width: 500px;
  min-height: 500px;
  position: absolute;
  left: 90px;
  top: 50px;
  border-radius: 4px;
}

.complex-img .btn-video {
  position: absolute;
  left: 50%;
  right: auto;
  z-index: 2;
  top: 45%;
  bottom: auto;
  display: block;
}

@media only screen and (max-width: 992px) {
  .complex-img {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;
  }
  .complex-img .img-two {
    width: calc(100% - 30px);
    left: 40px;
    top: 40px;
    border-radius: 4px;
  }
}

/* -------- "margin helpers" and "padding helpers"  --------- */
.mt5 {
  margin-top: 5px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.pt55 {
  padding-top: 55px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.pb55 {
  padding-bottom: 55px !important;
}

.ml55 {
  margin-left: 55px !important;
}

.pl55 {
  padding-left: 55px !important;
}

.mr55 {
  margin-right: 55px !important;
}

.pr55 {
  padding-right: 55px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.ml60 {
  margin-left: 60px !important;
}

.pl60 {
  padding-left: 60px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.mt65 {
  margin-top: 65px !important;
}

.pt65 {
  padding-top: 65px !important;
}

.mb65 {
  margin-bottom: 65px !important;
}

.pb65 {
  padding-bottom: 65px !important;
}

.ml65 {
  margin-left: 65px !important;
}

.pl65 {
  padding-left: 65px !important;
}

.mr65 {
  margin-right: 65px !important;
}

.pr65 {
  padding-right: 65px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.pt70 {
  padding-top: 70px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.pb70 {
  padding-bottom: 70px !important;
}

.ml70 {
  margin-left: 70px !important;
}

.pl70 {
  padding-left: 70px !important;
}

.mr70 {
  margin-right: 70px !important;
}

.pr70 {
  padding-right: 70px !important;
}

.mt75 {
  margin-top: 75px !important;
}

.pt75 {
  padding-top: 75px !important;
}

.mb75 {
  margin-bottom: 75px !important;
}

.pb75 {
  padding-bottom: 75px !important;
}

.ml75 {
  margin-left: 75px !important;
}

.pl75 {
  padding-left: 75px !important;
}

.mr75 {
  margin-right: 75px !important;
}

.pr75 {
  padding-right: 75px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.pt80 {
  padding-top: 80px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.pb80 {
  padding-bottom: 80px !important;
}

.ml80 {
  margin-left: 80px !important;
}

.pl80 {
  padding-left: 80px !important;
}

.mr80 {
  margin-right: 80px !important;
}

.pr80 {
  padding-right: 80px !important;
}

.mt85 {
  margin-top: 85px !important;
}

.pt85 {
  padding-top: 85px !important;
}

.mb85 {
  margin-bottom: 85px !important;
}

.pb85 {
  padding-bottom: 85px !important;
}

.ml85 {
  margin-left: 85px !important;
}

.pl85 {
  padding-left: 85px !important;
}

.mr85 {
  margin-right: 85px !important;
}

.pr85 {
  padding-right: 85px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.pt90 {
  padding-top: 90px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.pb90 {
  padding-bottom: 90px !important;
}

.ml90 {
  margin-left: 90px !important;
}

.pl90 {
  padding-left: 90px !important;
}

.mr90 {
  margin-right: 90px !important;
}

.pr90 {
  padding-right: 90px !important;
}

.mt95 {
  margin-top: 95px !important;
}

.pt95 {
  padding-top: 95px !important;
}

.mb95 {
  margin-bottom: 95px !important;
}

.pb95 {
  padding-bottom: 95px !important;
}

.ml95 {
  margin-left: 95px !important;
}

.pl95 {
  padding-left: 95px !important;
}

.mr95 {
  margin-right: 95px !important;
}

.pr95 {
  padding-right: 95px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.pt100 {
  padding-top: 100px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}

.ml100 {
  margin-left: 100px !important;
}

.pl100 {
  padding-left: 100px !important;
}

.mr100 {
  margin-right: 100px !important;
}

.pr100 {
  padding-right: 100px !important;
}

.mt120 {
  margin-top: 120px !important;
}

.pt120 {
  padding-top: 120px !important;
}

.mb120 {
  margin-bottom: 120px !important;
}

.pb120 {
  padding-bottom: 120px !important;
}

.ml120 {
  margin-left: 120px !important;
}

.pl120 {
  padding-left: 120px !important;
}

.mr120 {
  margin-right: 120px !important;
}

.pr120 {
  padding-right: 120px !important;
}

.mt-150 {
  margin-top: -150px;
}

.mt-100 {
  margin-top: -100px;
}

.half-img-section {
  position: relative;
}

.half-img-section .content-half {
  padding: 150px 15%;
}

.half-img-section .content-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
}

@media only screen and (max-width: 992px) {
  .half-img-section {
    padding: 0;
  }
  .half-img-section .content-img {
    display: none;
  }
  .half-img-section .content-half {
    padding: 50px 15px 25px;
  }
}

.bg-gray {
  background-color: #f7f7f7 !important;
}

.bg-light {
  background-color: #ffffff !important;
}

.color-primary {
  color: #e9204f !important;
}

.bg-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.overlay {
  background-color: rgba(27, 26, 26, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.color-light {
  color: #ffffff !important;
}

.color-light svg {
  fill: #ffffff !important;
}

.brand_item {
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 225px !important;
}

.brand_item img {
  margin: 0 auto;
  width: auto !important;
  max-width: 100% !important;
}

.brand_item_2 {
  padding: 20px;
  max-width: 225px !important;
  background-color: #ffffff;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 4px;
}

.brand_item_2 img {
  margin: 0 auto;
  width: auto !important;
  max-width: 100% !important;
}

.list-icon {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 15px;
}

.list-icon li {
  padding: 7px 0;
  color: #1b1a1a;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.list-icon li i {
  color: #e9204f;
  padding-right: 7px;
}

.spacer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-container {
  position: relative;
  padding-left: 20%;
}

@media only screen and (max-width: 992px) {
  .side-container {
    padding: 0;
  }
}

.services-item {
  padding: 15px;
}

.owl-carousel .owl-dots {
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: 9;
  margin-top: 20px;
}

.owl-carousel .owl-dots .owl-dot {
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
  transition: all 0.3s ease-in-out, transform 0.3s ease;
  background: transparent;
  position: relative;
  display: inline-block;
  margin: 0 5px;
  height: 10px;
  width: 10px;
}

.owl-carousel .owl-dots .owl-dot span {
  transition: all 0.3s ease-in-out, transform 0.3s ease;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 50%;
  background-color: #FFF;
  cursor: pointer;
  position: absolute;
  margin: 0 auto;
}

.owl-carousel .owl-dots .owl-dot.active {
  box-shadow: 0 0 0 2px #FFF;
  border: none;
  border-radius: 50%;
  background: transparent;
}

.owl-carousel .owl-dots .owl-dot.active span {
  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  background-color: #fff;
}

.container-box {
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  padding: 30px;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.04);
}

@keyframes loader-inner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**
 * Simple fade transition,
 */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* ------------------------------------- */
/* *. Header .......................... */
/* ------------------------------------- */
.header {
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.header .navbar-brand-sm {
  display: none;
}

.header .navbar-brand-lg {
  display: block;
}

.header .header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header .header-inner .nav-item .nav-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
  padding: 30px 10px;
  margin: 0 5px;
  display: block;
  text-transform: capitalize;
}

.header .header-inner .nav-item .nav-link:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 15px;
  background-color: #ffffff;
  width: 0;
  height: 2px;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.header .header-inner .nav-item .nav-link.has_sub_menu {
  padding-right: 20px;
}

.header .header-inner .nav-item .nav-link.has_sub_menu:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  right: 7px;
  font-size: 10px;
  content: "\f078";
}

.header a.nav-link.active_menu.has_sub_menu:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.header .sub_menu.depth-1 {
  right: 0;
  left: 100%;
  top: 0;
}

.header ul.sub_menu {
  background-color: #1b1a1a;
  position: absolute;
  padding: 5px 0;
  min-width: 220px;
  width: auto;
  height: auto;
  margin: 1px 0 0 0;
  opacity: 1;
  display: none;
  color: #ffffff;
  border-radius: 5px;
  border-bottom: solid 2px #e9204f;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.08);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.header ul.sub_menu li {
  display: block;
  padding: 0;
  margin: 0;
}

.header ul.sub_menu li a.nav-link {
  padding: 10px 15px !important;
  display: block;
  margin-left: 0;
  word-break: break-word;
  color: #ffffff;
  overflow: hidden;
  border-radius: 0;
}

.header ul.sub_menu li a.nav-link:hover {
  color: #e9204f;
}

.header ul.sub_menu li a.nav-link:before {
  content: none !important;
}

.header .social {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.header .social li {
  display: inline-block;
  padding: 5px 10px;
}

.header .social li a {
  padding: 5px;
  font-size: 16px;
}

.header.sticky {
  top: 0;
  width: 100%;
  padding: 0;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.header.sticky .nav-item .nav-link {
  padding: 20px 10px;
}

@media only screen and (max-width: 992px) {
  .header {
    position: relative;
    padding: 10px;
  }
  .header .navbar-brand-sm {
    display: block;
  }
  .header .navbar-brand-lg {
    display: none;
  }
  .header .header-inner {
    display: block;
    align-items: center;
    width: 100%;
    padding: 10px 0;
  }
  .header .header-inner .nav-item .nav-link {
    padding: 12px 5px;
  }
  .header .header-inner .nav-item .nav-link:before {
    content: none;
  }
  .header.sticky {
    padding: 10px;
    -webkit-transition: all ease-in-out 0.4s;
    -moz-transition: all ease-in-out 0.4s;
    -o-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
  }
  .header.sticky .nav-item .nav-link {
    padding: 12px 5px;
  }
  .header.sticky .nav-item .nav-link:before {
    content: none;
  }
}

.header_menu_btn {
  padding: 21px 25px;
  font-size: 22px;
  outline: 0 !important;
  box-shadow: 0 0 0 !important;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  background-color: transparent;
  border: 0;
  display: inline-block;
  text-align: center;
  margin: 0;
}

.header_menu_btn .navbar-toggler-icon {
  width: 20px;
  height: 3px;
  display: block;
  background-color: #080708;
  margin-bottom: 9px;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}

.header_menu_btn .navbar-toggler-icon:before {
  top: -8px;
  background-color: #333333;
  width: 13px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.header_menu_btn .navbar-toggler-icon:after {
  top: 8px;
  background-color: #333333;
  width: 30px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.header-dark {
  background-color: #1b1a1a;
  color: #ffffff;
}

.header-dark li a {
  color: #ffffff;
}

.header-dark li a:hover, .header-dark li a.active {
  color: #e9204f;
}

.header-dark li a:hover:before, .header-dark li a.active:before {
  width: 20px !important;
  background-color: #e9204f !important;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.header-dark .header_menu_btn .navbar-toggler-icon {
  background-color: #ffffff;
  margin-bottom: 9px;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}

.header-dark .header_menu_btn .navbar-toggler-icon:before {
  top: -8px;
  background-color: #ffffff;
  width: 13px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.header-dark .header_menu_btn .navbar-toggler-icon:after {
  top: 8px;
  background-color: #ffffff;
  width: 30px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.header-light {
  background-color: #ffffff;
  color: #1b1a1a;
}

.header-light li a {
  color: #1b1a1a;
}

.header-light li a:hover, .header-light li a.active {
  color: #e9204f;
}

.header-light li a:hover:before, .header-light li a.active:before {
  width: 20px !important;
  background-color: #e9204f !important;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.header-light .header_menu_btn .navbar-toggler-icon {
  background-color: #1b1a1a;
  margin-bottom: 9px;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}

.header-light .header_menu_btn .navbar-toggler-icon:before {
  top: -8px;
  background-color: #1b1a1a;
  width: 13px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.header-light .header_menu_btn .navbar-toggler-icon:after {
  top: 8px;
  background-color: #1b1a1a;
  width: 30px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.header-transparent {
  background-color: transparent;
  color: #ffffff;
}

.header-transparent li a {
  color: #ffffff;
}

.header-transparent li a:hover, .header-transparent li a.active {
  color: #f7f7f7;
}

.header-transparent li a:hover:before, .header-transparent li a.active:before {
  width: 20px !important;
  background-color: #e9204f !important;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.header-transparent.sticky {
  background-color: #1b1a1a;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.header-transparent .header_menu_btn .navbar-toggler-icon {
  background-color: #ffffff;
  margin-bottom: 9px;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}

.header-transparent .header_menu_btn .navbar-toggler-icon:before {
  top: -8px;
  background-color: #ffffff;
  width: 13px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.header-transparent .header_menu_btn .navbar-toggler-icon:after {
  top: 8px;
  background-color: #ffffff;
  width: 30px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

@media only screen and (max-width: 992px) {
  .header-transparent {
    background-color: #1b1a1a;
    -webkit-transition: all ease-in-out 0.4s;
    -moz-transition: all ease-in-out 0.4s;
    -o-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
  }
}

.header-top {
  position: relative;
  border-bottom: 1px solid #f7f7f7;
  font-size: 13px;
}

.header-top .header-top-item {
  display: inline-block;
  padding: 10px 10px 10px 0;
  color: #1b1a1a;
}

.header-top .header-top-item label {
  color: #e9204f;
  font-weight: 500;
  margin: 0;
}

.header-top.header-top-dark {
  border-bottom: 1px solid #131313;
  background-color: #131313;
}

.header-top.header-top-dark .header-top-item {
  color: #f7f7f7;
}

.header-top.header-top-dark .header-top-item label {
  color: #ffffff;
}

@media only screen and (max-width: 992px) {
  .header-top {
    width: 100%;
    display: block;
    padding: 10px 0;
  }
  .header-top .header-top-item {
    display: block;
    text-align: center;
    width: 100%;
    padding: 5px 10px 5px 0;
  }
  .header-top .header-top-meta {
    display: block;
    width: 100%;
    text-align: center;
  }
  .header-top .header-top-meta .header-top-item {
    width: auto;
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .header li:hover > ul {
    display: block;
    visibility: visible;
    animation: menuEffect 0.4s both;
    -webkit-transition: all ease-in-out 0.4s;
    -moz-transition: all ease-in-out 0.4s;
    -o-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
  }
}

@media (max-width: 992px) {
  .header li ul.sub_menu {
    position: static;
    visibility: visible;
    width: 100%;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
  }
  .header a.has_sub_menu.nav-link:after {
    margin-right: 15px;
    top: 12px;
  }
}

@-webkit-keyframes menuEffect {
  0% {
    opacity: 0;
    top: calc(100% + 20px);
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@keyframes menuEffect {
  0% {
    opacity: 0;
    top: calc(100% + 20px);
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

/* ------------------------------------- */
/* *. Footer .......................... */
/* ------------------------------------- */
.footer {
  position: relative;
  padding: 100px 0;
  width: 100%;
  background: #131313;
  border-top: solid 1px #1b1a1a;
}

.footer .logo {
  max-height: 35px;
  display: inline-block;
  margin-bottom: 10px;
}

@media only screen and (max-width: 992px) {
  .footer .logo {
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
  }
}

.footer .footer-copyright {
  font-size: 13px;
  color: #f7f7f7;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

@media only screen and (max-width: 992px) {
  .footer .footer-copyright {
    text-align: center;
    margin-bottom: 25px;
  }
}

.footer .footer-mail-info .title {
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 5px;
}

.footer .footer-mail-info p {
  font-size: 13px;
  color: #f7f7f7;
  line-height: 1em;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

@media only screen and (max-width: 992px) {
  .footer .footer-mail-info {
    text-align: center;
    margin-bottom: 5px;
  }
}

.footer .footer-social {
  margin-bottom: 0;
  text-align: center;
}

.footer .footer-social a {
  background: #1b1a1a;
  border: solid 2px #1b1a1a;
  padding: 6px 0;
  border-radius: 2px;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  font-size: 16px;
  color: #ffffff;
  text-align: center !important;
}

.footer .footer-social a:hover {
  background-color: #e9204f;
  border: solid 2px #e9204f;
  color: #ffffff;
  box-shadow: 0 0 0 0 rgba(27, 26, 26, 0.4);
}

@media only screen and (max-width: 992px) {
  .footer .footer-social {
    margin-bottom: 25px;
  }
}

.footer.footer-dark {
  background: #131313;
  border-top: solid 1px #1b1a1a;
}

.footer.footer-dark .footer-copyright .logo {
  color: #ffffff;
}

.footer.footer-dark .footer-copyright p {
  color: #f7f7f7;
}

.footer.footer-dark .footer-mail-info .title {
  color: #ffffff;
}

.footer.footer-dark .footer-mail-info p {
  color: #f7f7f7;
}

.footer.footer-dark .footer-social a {
  background: #1b1a1a;
  border: solid 2px #1b1a1a;
  color: #ffffff;
}

.footer.footer-dark .footer-social a:hover {
  background-color: #e9204f;
  border: solid 2px #e9204f;
  color: #ffffff;
  box-shadow: 0 0 0 0 rgba(27, 26, 26, 0.4);
}

.footer.footer-light {
  background: #f7f7f7;
  border-top: solid 1px #f7f7f7;
}

.footer.footer-light .footer-copyright .logo {
  color: #131313;
}

.footer.footer-light .footer-copyright p {
  color: #1b1a1a;
  font-weight: 500;
}

.footer.footer-light .footer-mail-info .title {
  color: #131313;
  font-weight: 700;
}

.footer.footer-light .footer-mail-info p {
  color: #1b1a1a;
  font-weight: 500;
}

.footer.footer-light .footer-social a {
  background: #1b1a1a;
  border: solid 2px #131313;
  color: #ffffff;
}

.footer.footer-light .footer-social a:hover {
  background-color: #e9204f;
  border: solid 2px #e9204f;
  color: #ffffff;
  box-shadow: 0 0 0 0 rgba(247, 247, 247, 0.4);
}

.footer.footer-shape {
  position: relative;
}

.footer.footer-shape .shape-sec {
  top: -100px;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .footer.footer-shape .shape-sec {
    top: -46px;
  }
}

.footer.footer-left * {
  text-align: left !important;
}

.footer-info .logo {
  margin-bottom: 30px;
  display: block;
}

.footer-info p {
  font-size: 15px;
  color: #efefef;
}

.footer-title {
  position: relative;
  margin-bottom: 40px;
}

.footer-title h4 {
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  position: relative;
}

.footer-title h4:before {
  height: 1px;
  background-color: #1b1a1a;
  position: absolute;
  bottom: -15px;
  width: 75%;
  content: " ";
}

.footer-title h4:after {
  height: 1px;
  background-color: #e9204f;
  position: absolute;
  bottom: -15px;
  width: 25%;
  content: " ";
  left: 6px;
}

@media only screen and (max-width: 992px) {
  .footer-title {
    margin-top: 25px;
  }
}

.footer-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-menu li {
  padding: 6px 0;
  color: #ffffff;
}

.footer-menu li a {
  color: #ffffff;
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.footer-menu li a:hover {
  color: #e9204f;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.footer-subscribe {
  padding: 40px;
  background-color: #1b1a1a;
  border: solid 1px #1b1a1a;
  border-radius: 4px;
  margin-bottom: 25px;
}

.footer-subscribe .input-group {
  position: relative;
  width: 100%;
}

.footer-subscribe .input-group input {
  width: 100%;
  padding: 12px 50px 12px 10px;
  border-radius: 2px;
  box-shadow: 0 0 0 !important;
  outline: 0 !important;
  border: 0 !important;
}

.footer-subscribe .input-group .form-btn {
  position: absolute;
  right: 0;
  width: 45px;
  height: 45px;
  background-color: transparent;
  border: 0 !important;
  outline: 0 !important;
  font-size: 16px;
}

/* ------------------------------------- */
/* *. Cover .......................... */
/* ------------------------------------- */
.cover {
  height: 80vh;
  min-height: 650px;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cover .cover-content {
  height: 80vh;
  min-height: 650px;
  display: flex;
  align-items: center;
}

.cover .cover-content .cover-title {
  color: #ffffff;
  font-size: 72px;
  line-height: 78px;
  margin-bottom: 30px;
}

.cover .cover-content .cover-desc {
  font-size: 19px;
  color: #f7f7f7;
}

.cover .cover-content .cover-btns {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.cover .cover-content .cover-btns .btn {
  margin-right: 40px;
}

.cover .overlay {
  background-color: rgba(19, 19, 19, 0.4);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.cover.cover-shape {
  position: relative;
}

.cover.cover-shape .shape-sec {
  bottom: 0;
  z-index: 0;
  height: 100px;
  width: 100%;
  left: 0;
  right: 0;
  position: absolute;
}

.cover.cover-slider {
  z-index: 0;
}

.cover.cover-slider .owl-item {
  padding: 0 !important;
}

.cover.cover-slider .cover-slider-item {
  float: none;
  height: 80vh;
  min-height: 650px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: block;
  position: relative;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.cover.cover-slider .cover-slider-item .cover-title {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s 600ms ease-in-out;
}

.cover.cover-slider .cover-slider-item .cover-desc {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s 900ms ease-in-out;
}

.cover.cover-slider .cover-slider-item .btn {
  opacity: 0;
  transform: translateY(20px);
  transition: transform  0.4s 1200ms ease-in-out, opacity 0.4s 1200ms ease-in-out, -webkit-transform  0.4s 1200ms ease-in-out;
}

.cover.cover-slider .active .cover-slider-item .cover-title {
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.4s 600ms ease-in-out;
}

.cover.cover-slider .active .cover-slider-item .cover-desc {
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.4s 900ms ease-in-out;
}

.cover.cover-slider .active .cover-slider-item .btn {
  opacity: 1;
  transform: translateY(0px);
  transition: transform  0.4s 1200ms ease-in-out, opacity 0.4s 1200ms ease-in-out, -webkit-transform  0.4s 1200ms ease-in-out;
}

.cover.cover-slider .owl-dots {
  bottom: 25px;
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.cover.cover-slider .owl-dots .owl-dot {
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
  transition: all 0.3s ease-in-out, transform 0.3s ease;
  background: transparent;
  position: relative;
  display: inline-block;
  margin: 0 5px;
  height: 10px;
  width: 10px;
}

.cover.cover-slider .owl-dots .owl-dot span {
  transition: all 0.3s ease-in-out, transform 0.3s ease;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 50%;
  background-color: #FFF;
  cursor: pointer;
  position: absolute;
  margin: 0 auto;
}

.cover.cover-slider .owl-dots .owl-dot.active {
  box-shadow: 0 0 0 2px #FFF;
  border: none;
  border-radius: 50%;
  background: transparent;
}

.cover.cover-slider .owl-dots .owl-dot.active span {
  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  background-color: #fff;
}

.cover.cover-slider .owl-nav {
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 9;
  left: 0;
  color: #FFF !important;
  font-size: 16px;
}

.cover.cover-slider .owl-nav [class*=owl-] {
  color: #FFFFFF !important;
  font-size: 16px;
  background-color: transparent !important;
  border: solid 1px rgba(255, 255, 255, 0.4) !important;
  margin: 0 10px;
  top: 45%;
  width: 50px;
  height: 50px;
  z-index: 99999;
  padding: 10px 12px !important;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  position: absolute;
  line-height: 1;
  transition: all ease-in-out 0.4s;
}

.cover.cover-slider .owl-nav [class*=owl-]:hover {
  background: rgba(0, 0, 0, 0.58) !important;
  border: solid 1px rgba(19, 19, 19, 0.4) !important;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.cover.cover-slider .owl-nav button.owl-prev {
  padding: 10px 12px !important;
  left: 0;
}

.cover.cover-slider .owl-nav button.owl-next {
  padding: 10px 12px !important;
  right: auto;
  left: calc(100vw - 90px);
}

.cover.cover-full {
  height: 100vh;
  min-height: 700px;
}

.cover.cover-full .cover-slider-item, .cover.cover-full .cover-content {
  height: 100vh;
  min-height: 700px;
}

@media only screen and (max-width: 992px) {
  .cover .cover-content .cover-title {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 25px;
  }
  .cover .cover-content .cover-desc {
    font-size: 16px;
  }
  .cover .cover-content .cover-btns {
    margin-top: 25px;
  }
  .cover .cover-content .cover-btns .btn {
    margin-right: 40px;
  }
}

.inner-cover {
  padding: 220px 0 200px 0;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f7f7f7;
}

.inner-cover .cover-heading {
  margin: 0;
  text-transform: capitalize;
  font-style: normal;
  color: #1b1a1a;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  font-size: 72px;
  line-height: 1.3em;
}

.inner-cover .cover-subheading {
  max-width: 600px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #9b9b9b;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  display: inline-block;
  line-height: 1.5625em;
}

.inner-cover .breadcrumbs-path {
  background: 0 0;
  font-weight: 500;
  position: relative;
  padding: 0;
  margin-top: 10px;
  max-width: 66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inner-cover .breadcrumbs-path a {
  font-weight: 500;
  margin-right: 10px;
  opacity: .7;
  color: #1b1a1a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.inner-cover .breadcrumbs-path a:hover {
  opacity: 1;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.inner-cover .breadcrumbs-path span {
  font-weight: 500;
  margin-right: 10px;
  color: #1b1a1a;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 1.5em;
}

.inner-cover .breadcrumbs-path i {
  margin-right: 10px;
  opacity: .5;
  color: #1b1a1a;
  font-size: 0.93758em;
  line-height: 1.5em;
}

.inner-cover .breadcrumbs-path i:last-of-type {
  opacity: 1;
}

.inner-cover.img-cover {
  color: #ffffff;
  background-color: transparent;
}

.inner-cover.img-cover .cover-heading {
  color: #ffffff;
}

.inner-cover.img-cover .cover-subheading {
  color: #f7f7f7;
}

.inner-cover.img-cover .breadcrumbs-path a {
  color: #ffffff;
}

.inner-cover.img-cover .breadcrumbs-path span {
  color: #ffffff;
}

.inner-cover.img-cover .breadcrumbs-path i {
  color: #ffffff;
}

.inner-cover.dark-cover {
  color: #ffffff;
  background-color: #131313;
}

.inner-cover.dark-cover .cover-heading {
  color: #ffffff;
}

.inner-cover.dark-cover .cover-subheading {
  color: #f7f7f7;
}

.inner-cover.dark-cover .breadcrumbs-path a {
  color: #e9204f;
  opacity: 1;
}

.inner-cover.dark-cover .breadcrumbs-path span {
  color: #ffffff;
}

.inner-cover.dark-cover .breadcrumbs-path i {
  color: #ffffff;
}

.inner-cover.light-cover {
  color: #1b1a1a;
  background-color: transparent;
}

.inner-cover.light-cover .cover-heading {
  color: #131313;
}

.inner-cover.light-cover .cover-subheading {
  color: #1b1a1a;
}

.inner-cover.light-cover .breadcrumbs-path a {
  color: #1b1a1a;
}

.inner-cover.light-cover .breadcrumbs-path span {
  color: #1b1a1a;
}

.inner-cover.light-cover .breadcrumbs-path i {
  color: #1b1a1a;
}

.inner-cover.content-center {
  text-align: center;
}

.inner-cover.content-center .breadcrumbs-path {
  margin-left: auto;
  margin-right: auto;
}

/* ------------------------------------- */
/* *. Buttons .......................... */
/* ------------------------------------- */
.btn {
  position: relative;
  font-family: "Roboto", sans-serif;
  outline: 0 !important;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0;
  color: #ffffff;
  text-transform: capitalize;
  line-height: 16px;
  text-decoration: none;
  background-color: #e9204f;
  border: 0;
  padding: 18px 30px;
  padding-right: 70px;
  box-shadow: 0 0 0 !important;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.btn span {
  background: #131313 none repeat scroll 0 0;
  border-radius: 50%;
  display: block;
  height: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transition: all ease-in-out 0.6s;
  -moz-transition: all ease-in-out 0.6s;
  -o-transition: all ease-in-out 0.6s;
  transition: all ease-in-out 0.6s;
  width: 0;
  z-index: -1;
}

.btn:before {
  position: absolute;
  height: 2px;
  width: 24px;
  background-color: #ffffff;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  content: "";
}

.btn:hover {
  background-color: #df1f4c;
  color: #ffffff;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.btn:hover span {
  height: 400px;
  width: 400px;
  z-index: -1;
  -webkit-transition: all ease-in-out 0.6s;
  -moz-transition: all ease-in-out 0.6s;
  -o-transition: all ease-in-out 0.6s;
  transition: all ease-in-out 0.6s;
}

.btn-video {
  position: relative;
  background-color: #ffffff;
  border-radius: 100%;
  border: 0 !important;
  box-shadow: 0 0 0  !important;
  display: inline-block;
  margin: 0;
  text-align: center;
  color: #1b1a1a;
  line-height: 1;
  font-size: 16px;
  height: 60px;
  width: 60px;
  z-index: 2;
  padding: 22px 20px;
  -webkit-transition: all ease-in-put 0.4s;
  -moz-transition: all ease-in-put 0.4s;
  -o-transition: all ease-in-put 0.4s;
  transition: all ease-in-put 0.4s;
}

.btn-video .line-video-1, .btn-video .line-video-2, .btn-video .line-video-3 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  animation: fadeOutCircle infinite 2.6s ease-in-out;
}

.btn-video .line-video-2 {
  animation-delay: .7s;
}

.btn-video .line-video-3 {
  animation-delay: 1.5s;
}

.btn-video:hover {
  color: #ffffff;
  border: 0;
  outline: 0;
  background: #1b1a1a none repeat scroll 0 0;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.btn-video.btn-big {
  height: 120px;
  width: 120px;
  padding: 44px;
  font-size: 32px;
}

.btn-video.btn-main {
  background-color: #e9204f;
}

.btn-dark {
  background-color: #1b1a1a;
  color: #ffffff;
  border-color: #1b1a1a;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.btn-dark:hover {
  color: #ffffff;
  background-color: #1b1a1a;
  border-color: #1b1a1a;
}

.btn-light {
  background-color: #ffffff;
  color: #1b1a1a;
  border-color: #ffffff;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.btn-light:before {
  background: #131313;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.btn-light:hover {
  color: #ffffff;
  background-color: #ffffff;
  border-color: #ffffff;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.btn-light:hover:before {
  background: #ffffff;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.btn-main {
  color: #ffffff;
  background-color: #e9204f;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.btn-main:hover {
  background-color: #df1f4c;
  color: #ffffff;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

@keyframes fadeOutCircle {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

/* ------------------------------------- */
/* *. section Title .......................... */
/* ------------------------------------- */
.section-title {
  position: relative;
}

.section-title .sub-title {
  color: #e9204f;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.5em;
}

.section-title .title {
  font-size: 44px;
  line-height: 1.2;
  letter-spacing: -.02em;
  margin-bottom: 30px;
}

.section-title .big-title {
  color: #ffffff;
  font-size: 60px;
  line-height: 70px;
  margin-top: 20px;
  margin-bottom: 0;
}

.section-title .desc {
  margin-bottom: 30px;
  font-size: 16px;
}

@media only screen and (max-width: 992px) {
  .section-title .title {
    font-size: 36px;
  }
  .section-title .big-title {
    font-size: 56px;
    line-height: 60px;
  }
  .section-title .desc {
    font-size: 14px;
  }
}

/* ------------------------------------- */
/* *. Icon Box .......................... */
/* ------------------------------------- */
.icon-box-one {
  margin: 25px 0;
  position: relative;
  cursor: pointer;
  display: block;
}

.icon-box-one .icon {
  margin: 0 0 20px;
  max-width: 70px;
  overflow: hidden;
  color: #e9204f;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.icon-box-one .icon svg, .icon-box-one .icon svg * {
  fill: #e9204f;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.icon-box-one .title {
  margin-bottom: 18px;
  font-size: 21px;
  font-weight: 600;
}

.icon-box-one .desc {
  font-size: 15px;
  line-height: 27px;
}

.icon-box-one.text-center {
  text-align: center;
}

.icon-box-one.text-center .icon {
  margin: 0 auto 20px;
}

.icon-box-one.text-right {
  text-align: right;
}

.icon-box-one.text-right .icon {
  margin: 0 0 20px auto;
}

.icon-box-one:hover .icon {
  color: #1b1a1a;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.icon-box-one:hover .icon svg, .icon-box-one:hover .icon svg * {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  fill: #1b1a1a;
}

.icon-box-two {
  margin: 15px 0;
  position: relative;
  cursor: pointer;
  text-align: left;
  padding: 40px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #efefef;
  color: #1b1a1a;
  transform: translateY(0);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-two .icon {
  margin: 0 0 20px;
  max-width: 60px;
  overflow: hidden;
  color: #e9204f;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.icon-box-two .icon svg, .icon-box-two .icon svg * {
  fill: #e9204f;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.icon-box-two .title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #131313;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-two .desc {
  font-size: 14px;
  line-height: 25px;
}

.icon-box-two.text-center {
  text-align: center;
}

.icon-box-two.text-center .icon {
  margin: 0 auto 20px;
}

.icon-box-two.text-right {
  text-align: right;
}

.icon-box-two.text-right .icon {
  margin: 0 0 20px auto;
}

.icon-box-two:hover {
  background-color: #1b1a1a;
  border: solid 1px #1b1a1a;
  transform: translateY(-10px);
  color: #f7f7f7;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-two:hover .icon {
  color: #e9204f;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.icon-box-two:hover .icon svg, .icon-box-two:hover .icon svg * {
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  fill: #e9204f;
}

.icon-box-two:hover .title {
  color: #ffffff;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-three {
  background-color: #ffffff;
  border-style: solid;
  border-width: 0;
  margin: 15px 0;
  position: relative;
  padding: 60px 50px 52px;
  box-shadow: 0 3px 53px rgba(40, 40, 40, 0.05);
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 4px;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-three .icon {
  width: 75px;
  height: 75px;
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}

.icon-box-three .icon svg, .icon-box-three .icon svg * {
  fill: #e9204f;
}

.icon-box-three .font-icon {
  font-size: 68px;
  padding: 0;
  background-color: transparent;
  color: #e9204f;
}

.icon-box-three .content {
  position: relative;
}

.icon-box-three .content .title {
  color: #1b1a1a;
  padding-top: 10px;
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.icon-box-three .content .desc {
  font-size: 15px;
}

.icon-box-three .content .link {
  font-size: 14px;
  color: #1b1a1a !important;
  font-weight: 500;
  position: relative;
  width: 22px;
  stroke-width: 1.3;
  stroke-miterlimit: 10;
  stroke: #1b1a1a;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-three .content .link:hover {
  color: #e9204f !important;
  box-shadow: 12px 19px 62px 0 rgba(13, 52, 79, 0.07);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-three .content .link:hover:after {
  width: 100%;
  left: 0;
  background-color: #e9204f;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-three:hover {
  transform: translateY(-10px);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-four {
  padding: 15px;
  margin: 15px 0;
  cursor: pointer;
  position: relative;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-four .icon {
  left: 15px;
  top: 25px;
  position: absolute;
  font-size: 36px;
  padding: 1px;
  width: 60px;
  height: 60px;
  text-align: center;
  background-color: #e9204f;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 20px;
  border-radius: 4px;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-four .icon svg {
  width: 65px;
  fill: #1b1a1a;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.icon-box-four .icon svg * {
  fill: #1b1a1a;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.icon-box-four .content {
  text-align: left;
  margin-left: 80px;
}

.icon-box-four .content .title {
  color: #1b1a1a;
  padding-top: 10px;
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.icon-box-four .content .desc {
  font-size: 14px;
}

.icon-box-four .content .link {
  font-size: 14px;
  color: #1b1a1a !important;
  font-weight: 500;
  position: relative;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-four .content .link:after {
  content: "";
  position: absolute;
  width: 10px;
  background-color: #1b1a1a;
  height: 2px;
  top: 100%;
  left: 0;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-four .content .link:hover {
  color: #e9204f !important;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-four .content .link:hover:after {
  width: 100%;
  left: 0;
  background-color: #e9204f;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-four:hover i {
  color: #e9204f;
  background-color: #ffffff;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.icon-box-four:hover .icon {
  color: #e9204f;
  background-color: #ffffff;
}

.icon-box-four:hover .icon svg {
  width: 65px;
  fill: #e9204f;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.icon-box-four:hover .icon svg * {
  fill: #e9204f;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.icon-box-four.text-right .icon {
  right: 15px;
  left: auto;
}

.icon-box-four.text-right .content {
  text-align: right;
  margin-right: 80px;
  margin-left: 0;
}

/* ------------------------------------- */
/* *. Counter Box .......................... */
/* ------------------------------------- */
.counter-box-one {
  position: relative;
}

.counter-box-one .icon {
  font-size: 64px;
  margin-bottom: 10px;
  display: block;
  line-height: 1;
  color: #e9204f;
}

.counter-box-one .icon i {
  line-height: 1;
}

.counter-box-one .icon svg {
  fill: #e9204f;
  width: 65px;
}

.counter-box-one .number, .counter-box-one .counter {
  font-family: "Barlow", sans-serif;
  font-size: 67px;
  color: #1b1a1a;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
  display: block;
}

.counter-box-one .title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #131313;
}

@media only screen and (max-width: 992px) {
  .counter-box-one {
    position: relative;
    margin: 25px 0;
  }
}

.counter-box-two {
  position: relative;
  padding: 30px 5px;
  background-color: #ffffff;
  border-radius: 4px;
  padding-left: 85px;
}

.counter-box-two .icon {
  width: 70px;
  position: absolute;
  left: 0;
  margin: 0;
  color: #e9204f;
  display: block;
  line-height: 1;
  font-size: 64px;
}

.counter-box-two .icon i {
  line-height: 1;
}

.counter-box-two .icon svg {
  fill: #e9204f;
}

.counter-box-two .numberm, .counter-box-two .counter {
  font-family: "Barlow", sans-serif;
  font-size: 48px;
  color: #1b1a1a;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
  display: block;
}

.counter-box-two .title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #131313;
}

/* ------------------------------------- */
/* *. Progress Bar .......................... */
/* ------------------------------------- */
.progress-container {
  margin-bottom: 25px;
}

.progress-container .progress-data {
  color: #1b1a1a;
  margin-bottom: 5px;
  position: relative;
  -webkit-transition: all ease-in-out 0.7s;
  -moz-transition: all ease-in-out 0.7s;
  -o-transition: all ease-in-out 0.7s;
  transition: all ease-in-out 0.7s;
}

.progress-container .progress-data .progress-title {
  color: #1b1a1a;
  position: relative;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  text-transform: capitalize;
  clear: both;
  line-height: 1.2;
  font-size: 15px;
  margin-bottom: 8px;
}

.progress-container .progress-data .precent {
  position: absolute;
  right: 100%;
  font-size: 15px;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  -webkit-transition: all ease-in-out 0.7s;
  -moz-transition: all ease-in-out 0.7s;
  -o-transition: all ease-in-out 0.7s;
  transition: all ease-in-out 0.7s;
}

.progress-container .progress {
  height: 2px;
  border-radius: 50px;
  position: relative;
  overflow: visible;
}

.progress-container .progress .progress-bar {
  background-color: #e9204f;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 0;
  -webkit-transition: all ease-in-out 0.7s;
  -moz-transition: all ease-in-out 0.7s;
  -o-transition: all ease-in-out 0.7s;
  transition: all ease-in-out 0.7s;
}

.progress-container .progress .progress-bar:after {
  position: absolute;
  display: block;
  content: "";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  right: -4px;
  top: -9px;
  width: 20px;
  height: 20px;
  background-color: rgba(233, 32, 79, 0.2);
}

.progress-container .progress .progress-bar:before {
  position: absolute;
  display: block;
  content: "";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  right: 0;
  top: -5px;
  width: 12px;
  height: 12px;
  background-color: #e9204f;
}

.progress-container .progress .progress-bar.color-light {
  background-color: #ffffff;
}

.progress-container .progress .progress-bar.color-light:after {
  background-color: rgba(255, 255, 255, 0.2);
}

.progress-container .progress .progress-bar.color-light:before {
  background-color: #ffffff;
}

.progress-container .progress .progress-bar.color-dark {
  background-color: #1b1a1a;
}

.progress-container .progress .progress-bar.color-dark:after {
  background-color: rgba(27, 26, 26, 0.2);
}

.progress-container .progress .progress-bar.color-dark:before {
  background-color: #1b1a1a;
}

.progress-container .progress.md {
  height: 5px;
}

.progress-container .progress.lg {
  height: 8px;
}

.progress-container .progress.color-light {
  background-color: rgba(255, 255, 255, 0.2);
}

@media all and (max-width: 767px) {
  .prog_container .prog_text .precent {
    position: sticky;
    right: 0;
  }
}

/*  ------------------------------
/* *.PRICING SECTION .......................... */
/*-------------------------------------- */
.price-box {
  background-color: #ffffff;
  text-align: center;
  border-radius: 1px;
  padding: 50px 35px;
  overflow: hidden;
  position: relative;
  border: 1px solid #f7f7f7;
  margin: 15px 0;
  transform: translateY(0);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.price-box .price-box-header {
  padding-bottom: 15px;
  border-bottom: 1px solid #f7f7f7;
}

.price-box .price-box-header .icon {
  font-size: 56px;
  color: #131313;
  max-width: 65px;
  margin: 0 auto;
  margin-bottom: 25px;
  display: inline-block;
}

.price-box .price-box-header .price-name {
  text-transform: uppercase;
  color: #131313;
  font-weight: 700;
  font-size: 16px;
  font-family: "Barlow", sans-serif;
}

.price-box .price-box-header .price-for {
  margin-top: 0;
  font-weight: 500;
  font-size: 13px;
  color: #e9204f;
  margin-bottom: 0;
  text-transform: capitalize;
  font-family: "Barlow", sans-serif;
}

.price-box .price-box-header .price-val {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 76px;
  color: #1b1a1a;
  margin-bottom: 0;
}

.price-box .price-box-header .price-val sup {
  top: -2.5em;
  font-size: 18px;
  font-weight: 600;
  left: 10px;
  padding: 0;
}

.price-box .price-box-content {
  padding: 25px 0;
}

.price-box .price-box-content p {
  text-align: center;
  text-transform: capitalize;
  font-size: 14px;
}

.price-box .price-box-content ul {
  list-style: none;
}

.price-box .price-box-content ul li {
  padding: 7px 10px;
  text-align: left;
  color: #1b1a1a;
  font-size: 13px;
  display: block;
  margin: 0;
}

.price-box .price-box-content ul li span {
  font-size: 14px;
  line-height: 1.8em;
  font-weight: 500;
}

.price-box .price-box-content ul li i {
  padding: 8px 0 0 5px;
  color: #e9204f;
  float: right;
}

.price-box .price-box-content ul li i.icon-right {
  color: #29cf8a;
}

.price-box .price-box-content ul li i.icon-del {
  color: #F55767;
}

.price-box .price-footer {
  position: relative;
  margin-top: 20px;
}

.price-box:hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.price-box.featured {
  box-shadow: 0 1px 50px rgba(40, 40, 40, 0.08);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.price-box.featured .featured-mark {
  position: absolute;
  top: 15px;
  right: -40px;
  background-color: #e9204f;
  display: block;
  padding: 6px 50px;
  border-radius: 2px;
  font-size: 12px;
  color: #ffffff;
  transform: rotate(45deg);
}

/* ------------------------------------- */
/* *. Portfolio  .......................... */
/* ------------------------------------- */
.portfolio-box {
  position: relative;
  background-color: #f7f7f7;
  min-height: 500px;
  width: 100%;
  overflow: hidden;
  margin: 15px 0;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.portfolio-box .portfolio-inner {
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border: 0 solid transparent;
  outline: 0 solid transparent;
}

.portfolio-box .portfolio-inner .portfolio-info {
  position: absolute;
  background-color: #ffffff;
  left: 20px !important;
  top: 20px !important;
  right: 20px !important;
  bottom: 20px !important;
  width: calc(100% - 40px) !important;
  height: calc(100% - 40px) !important;
  transform-origin: 50% 50% 0;
  transform-style: flat;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: matrix3d(0.2, 0, 0, 0, 0, 0.2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.portfolio-box .portfolio-inner .portfolio-info .category {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #e9204f;
  text-transform: capitalize;
  transform: translateX(-10px);
  transition-delay: 3s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.portfolio-box .portfolio-inner .portfolio-info .title {
  font-size: 18px;
  cursor: pointer;
  text-transform: capitalize;
  transform: translateX(10px);
  transition-delay: 3s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.portfolio-box .portfolio-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.portfolio-box:hover .portfolio-img {
  transform: scale(0.9);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.portfolio-box:hover .portfolio-inner .portfolio-info {
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.portfolio-box:hover .portfolio-inner .portfolio-info .category {
  transform: translateX(0px);
  transition-delay: 6s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.portfolio-box:hover .portfolio-inner .portfolio-info .title {
  transform: translateX(0px);
  transition-delay: 6s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.portfolio-container .col-lg-4 {
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .portfolio-container .col-lg-4 {
    padding: 0;
  }
}

.portfolio-categories {
  text-align: center;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
}

.portfolio-categories li {
  display: inline-block;
  padding: 10px;
  font-family: "Barlow", sans-serif;
  color: #1b1a1a;
  text-transform: capitalize;
  font-size: 15px;
  cursor: pointer;
  font-weight: 700;
  margin: 10px 10px;
}

.portfolio-categories li.active {
  color: #e9204f;
}

.work-carousel .item {
  padding: 15px;
}

/* ------------------------------------- */
/* *. Tesimonial  .......................... */
/* ------------------------------------- */
.testimonial-box {
  position: relative;
  margin: 15px 0;
}

.testimonial-box .testimonial-img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 30px;
}

.testimonial-box .testimonial-text {
  font-size: 16px;
  color: #1b1a1a;
}

.testimonial-box .testimonial-text:before {
  position: absolute;
  font-size: 180px;
  opacity: 0.04;
  font-family: 'Font Awesome 5 Free';
  color: #e9204f;
  font-weight: 900 !important;
  content: "\f10e";
  top: 0;
}

.testimonial-box .testimonial-name {
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonial-box .testimonial-position {
  margin-top: 0;
  font-size: 12px;
  line-height: 1.3em;
  text-transform: capitalize;
  color: #e9204f;
}

.testimonial-two.owl-carousel .owl-dot.active span {
  background-color: #e9204f !important;
  width: 8px !important;
  height: 8px !important;
}

.testimonial-two.owl-carousel .owl-dot span {
  background-color: rgba(27, 26, 26, 0.4) !important;
  width: 6px !important;
  height: 6px !important;
}

.testimonial-two.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
}

.testimonial-box-two {
  position: relative;
  margin: 15px 35px;
  padding: 5px;
}

.testimonial-box-two .icon {
  color: #e9204f;
  font-size: 64px;
}

.testimonial-box-two .t-content p {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 400;
  margin: 25px 0;
  color: #1b1a1a;
  clear: both;
  text-align: center;
  font-family: "Barlow", sans-serif;
}

@media only screen and (max-width: 992px) {
  .testimonial-box-two .t-content p {
    font-size: 16px;
  }
}

.testimonial-box-two .t-info {
  position: relative;
  font-size: 16px;
}

.testimonial-box-two .t-info .t-name {
  margin: 0;
  line-height: 1.3;
  font-size: 18px;
  font-weight: 600;
}

.testimonial-box-two .t-info .t-job {
  font-size: 12px;
  margin: 0 4px;
  line-height: 1;
  color: #1b1a1a;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
}

/* ------------------------------------- */
/* *. Team Box  .......................... */
/* ------------------------------------- */
.team_box {
  border-radius: 0;
  padding-bottom: 0;
  margin: 15px 0;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.team_box .team_img {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
}

.team_box .team_img img {
  width: 100%;
}

.team_box .team_box_content {
  padding: 20px 20px;
  background-color: #ffffff;
  z-index: 1;
  position: relative;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.team_box .team_box_content h5 {
  color: #1b1a1a;
  font-weight: 600;
  text-transform: capitalize;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  margin-bottom: 6px;
  font-size: 21px;
}

.team_box .team_box_content p {
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.team_box .team_box_content h6 {
  margin: 0;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 10px;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  font-size: 13px;
  color: #e9204f;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.team_box .team_box_content ul.team_social {
  color: #131313;
  width: 100%;
  bottom: 5px;
  float: none;
  padding: 0;
}

.team_box .team_box_content ul.team_social li {
  display: inline-block;
  padding: 0;
}

.team_box .team_box_content ul.team_social li a {
  color: #1b1a1a;
  display: block;
  border-radius: 2px;
  font-size: 20px;
  padding: 8px;
  margin: 0 5px;
}

.team_box .team_box_content ul.team_social li a:hover {
  color: #e9204f;
}

.team_box:hover .team_box_content {
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.team_box:hover .team_box_content h5 {
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.team_box:hover .team_box_content p {
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.team_box:hover .team_box_content h6 {
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

/* ------------------------------------- */
/* *. Blog Box  .......................... */
/* ------------------------------------- */
.blog-post-one {
  position: relative;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  display: inline-block;
  overflow: hidden;
  border-radius: 4px;
  margin: 15px 0;
  text-align: left;
}

.blog-post-one figure {
  max-height: 250px;
  overflow: hidden;
  margin: 0;
}

.blog-post-one figure img {
  min-height: 250px;
  border-top-left-radius: 4px;
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 4px;
}

.blog-post-one .info-post {
  position: relative;
  padding: 20px 15px 30px;
  background-color: #ffffff;
  float: left;
  width: 100%;
  clear: both;
}

.blog-post-one .info-post .cat-name {
  position: absolute;
  text-transform: capitalize;
  text-decoration: none;
  color: #ffffff;
  left: 15px;
  top: -15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  z-index: 4;
  overflow: hidden;
  border-radius: 4px;
  background-color: #e9204f;
  padding: 3px 10px;
}

.blog-post-one .info-post .post-title {
  font-size: 21px;
  line-height: 29px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 10px;
  position: relative;
  text-transform: capitalize;
  font-family: "Barlow", sans-serif;
}

.blog-post-one .info-post .post-title a {
  color: inherit;
  text-decoration: none;
}

.blog-post-one .info-post .post-title:hover {
  color: #e9204f;
}

.blog-post-one .info-post p {
  margin: 0 0 10px;
  width: 100%;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0;
}

.blog-post-one .info-post .footer-info-post {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

.blog-post-one .info-post .meta-post {
  padding: 0;
  margin: 0;
}

.blog-post-one .info-post .meta-post li {
  position: relative;
  display: inline-block;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 10px;
  margin: 0;
}

.blog-post-one .info-post .meta-post li i {
  padding-right: 5px;
  font-size: 12px;
  position: relative;
  color: #e9204f;
}

.blog-post-one .info-post .arrow-btn {
  position: relative;
  width: 22px;
  stroke-width: 1.3;
  stroke-miterlimit: 10;
  stroke: #1b1a1a;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.blog-post-one .info-post .arrow-btn .line {
  stroke-dashoffset: 9.20001;
  stroke-dasharray: 18.4, 28.4;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.blog-post-one .info-post .arrow-btn:hover svg .line {
  stroke-dashoffset: 1e-05;
  stroke-dasharray: 18.4, 28.4;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.blog-post-one:hover {
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

/* ------------------------------------- */
/* *. Forms  .......................... */
/* ------------------------------------- */
input[type="text"], input[type="number"], input[type="email"], input[type="url"], input[type="tel"], input[type="search"], input[type="password"], textarea, select {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5em;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0;
  font-family: "Roboto", sans-serif;
}

.form-control {
  font-size: 14px;
  padding: 12px 15px;
  height: 50px;
  border-radius: 5px;
  border: solid 1px #f5f5f5;
  margin-bottom: 25px;
  outline: 0;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.form-control:hover, .form-control:focus {
  box-shadow: 0 0 0;
  outline: 0 !important;
  border-color: #1b1a1a;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.form-control.form-rounded {
  border-radius: 150px;
}

textarea {
  min-height: 200px;
}

textarea.form-rounded {
  border-radius: 15px !important;
}

.input-group .form-control {
  height: 46px;
  border: solid 1px #f7f7f7;
}

.form-horizontal .form-control {
  margin: 0 !important;
}

.form-horizontal .btn {
  margin: 0 !important;
}

.content-map {
  position: relative;
}

.map-float {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f7f7f7;
}

.map-container {
  min-height: 500px;
  width: 100%;
}

.contact-img-box {
  background-color: #ffffff;
  z-index: 99;
  position: relative;
  border-radius: 0;
  overflow: hidden;
  box-shadow: 5px 5px 15px 0 rgba(17, 17, 17, 0.06);
}

.contact-img-box .contact-box-data {
  padding: 25px;
}

@media only screen and (max-width: 992px) {
  .contact-img-box .contact-box-data {
    padding: 25px 15px;
  }
}

.contact-img-box .contact-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* ------------------------------------- */
/* *. Img BoX  .......................... */
/* ------------------------------------- */
.img-box-one {
  position: relative;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  display: inline-block;
  overflow: hidden;
  border-radius: 2px;
  margin: 15px 0;
  text-align: left;
}

.img-box-one figure {
  max-height: 250px;
  overflow: hidden;
  margin: 0;
}

.img-box-one figure img {
  min-height: 250px;
  border-top-left-radius: 4px;
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 4px;
}

.img-box-one .info-post {
  position: relative;
  padding: 20px 15px 30px;
  float: left;
  width: 100%;
  clear: both;
  background-color: #ffffff;
}

.img-box-one .info-post .cat-name {
  position: absolute;
  text-transform: capitalize;
  text-decoration: none;
  color: #ffffff;
  left: 15px;
  top: -15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  z-index: 4;
  overflow: hidden;
  border-radius: 4px;
  background-color: #e9204f;
  padding: 3px 10px;
}

.img-box-one .info-post .post-title {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 10px;
  position: relative;
  text-transform: capitalize;
  font-family: "Barlow", sans-serif;
}

.img-box-one .info-post .post-title a {
  color: inherit;
  text-decoration: none;
}

.img-box-one .info-post .post-title:hover {
  color: #e9204f;
}

.img-box-one .info-post p {
  margin: 0 0 10px;
  width: 100%;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0;
}

.img-box-one .info-post .footer-info-post {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

.img-box-one .info-post .meta-post {
  padding: 0;
  margin: 0;
}

.img-box-one .info-post .meta-post li {
  position: relative;
  display: inline-block;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 10px;
  margin: 0;
}

.img-box-one .info-post .meta-post li i {
  padding-right: 5px;
  font-size: 12px;
  position: relative;
  color: #e9204f;
}

.img-box-one .info-post .arrow-btn {
  position: relative;
  width: 22px;
  stroke-width: 1.3;
  stroke-miterlimit: 10;
  stroke: #1b1a1a;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.img-box-one .info-post .arrow-btn .line {
  stroke-dashoffset: 9.20001;
  stroke-dasharray: 18.4, 28.4;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.img-box-one .info-post .arrow-btn:hover svg .line {
  stroke-dashoffset: 1e-05;
  stroke-dasharray: 18.4, 28.4;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.img-box-one:hover {
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.img-box-two {
  position: relative;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  display: inline-block;
  overflow: hidden;
  border-radius: 2px;
  margin: 15px 0;
  text-align: left;
}

.img-box-two figure {
  max-height: 250px;
  overflow: hidden;
  margin: 0;
}

.img-box-two figure img {
  min-height: 250px;
  border-top-left-radius: 4px;
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 4px;
}

.img-box-two .info-post {
  position: relative;
  padding: 20px 15px 30px;
  float: left;
  width: 100%;
  clear: both;
  background-color: #ffffff;
}

.img-box-two .info-post .icon {
  position: absolute;
  text-transform: capitalize;
  text-decoration: none;
  color: #ffffff;
  left: 15px;
  top: -60px;
  width: 75px;
  height: 75px;
  border-radius: 10px;
  margin-bottom: 15px;
  z-index: 1;
  background-color: #ffffff;
  padding: 10px;
}

.img-box-two .info-post .icon svg, .img-box-two .info-post .icon svg * {
  fill: #e9204f;
}

.img-box-two .info-post .post-title {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 10px;
  position: relative;
  text-transform: capitalize;
  font-family: "Barlow", sans-serif;
}

.img-box-two .info-post .post-title a {
  color: inherit;
  text-decoration: none;
}

.img-box-two .info-post .post-title:hover {
  color: #e9204f;
}

.img-box-two .info-post p {
  margin: 0 0 10px;
  width: 100%;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0;
}

.img-box-two .info-post .footer-info-post {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

.img-box-two .info-post .meta-post {
  padding: 0;
  margin: 0;
}

.img-box-two .info-post .meta-post li {
  position: relative;
  display: inline-block;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 10px;
  margin: 0;
}

.img-box-two .info-post .meta-post li i {
  padding-right: 5px;
  font-size: 12px;
  position: relative;
  color: #e9204f;
}

.img-box-two .info-post .arrow-btn {
  position: relative;
  width: 22px;
  stroke-width: 1.3;
  stroke-miterlimit: 10;
  stroke: #1b1a1a;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.img-box-two .info-post .arrow-btn .line {
  stroke-dashoffset: 9.20001;
  stroke-dasharray: 18.4, 28.4;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.img-box-two .info-post .arrow-btn:hover svg .line {
  stroke-dashoffset: 1e-05;
  stroke-dasharray: 18.4, 28.4;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.img-box-two:hover {
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

/* ------------------------------------- */
/* *. faq  .......................... */
/* ------------------------------------- */
.accordion-item {
  margin-bottom: 20px;
}

.accordion-item .accordion-title {
  margin: 0;
  padding: 15px;
  padding-left: 70px;
  position: relative;
  cursor: pointer;
  border: 1px solid #f7f7f7;
}

.accordion-item .accordion-title:before {
  content: "\f107";
  position: absolute;
  left: 0;
  top: -2px;
  bottom: -2px;
  width: 57px;
  text-align: center;
  line-height: 65px;
  font-weight: 600;
  font-family: 'Font Awesome 5 Free';
  font-size: 18px;
  color: #1b1a1a;
  border-right: 1px solid #f7f7f7;
}

.accordion-item .accordion-title h5 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
}

.accordion-item .accordion-title.active:before {
  content: "\f106";
  color: #e9204f;
}

.accordion-item .accordion-content {
  display: none;
  padding: 20px 0;
}

.accordion-item .accordion-content.active {
  display: block;
}

.accordion-item-two {
  margin-bottom: 20px;
}

.accordion-item-two .accordion-title {
  margin: 0;
  padding: 15px;
  padding-left: 70px;
  position: relative;
  cursor: pointer;
  border: 1px solid #e9204f;
}

.accordion-item-two .accordion-title:before {
  content: "\f067";
  position: absolute;
  left: 0;
  top: -1px;
  bottom: -1px;
  width: 57px;
  text-align: center;
  line-height: 65px;
  font-weight: 600;
  font-family: 'Font Awesome 5 Free';
  font-size: 18px;
  color: #1b1a1a;
  border-right: 1px solid #e9204f;
}

.accordion-item-two .accordion-title h5 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
}

.accordion-item-two .accordion-title.active:before {
  content: "\f068";
  color: #e9204f;
}

.accordion-item-two .accordion-content {
  display: none;
  padding: 20px;
  border: 1px solid #e9204f;
  border-top: 0;
}

.accordion-item-two .accordion-content.active {
  display: block;
}

/* ------------------------------------- */
/* *. Blog  .......................... */
/* ------------------------------------- */
.blog-pagination {
  display: inline-block;
  margin: 50px auto;
  float: none;
  width: 100%;
  text-align: center;
}

.blog-pagination a, .blog-pagination span {
  display: inline-block;
  width: 40px;
  height: 40px;
  color: #e9204f;
  line-height: 39px;
  margin-right: 7px;
  font-size: 15px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.blog-pagination a i, .blog-pagination span i {
  margin-left: 7px;
  margin-right: 7px;
}

.blog-pagination a.current, .blog-pagination a:hover, .blog-pagination span.current, .blog-pagination span:hover {
  color: #fff;
  background-color: #e9204f;
}

.blog-pagination a.prev, .blog-pagination a.next, .blog-pagination span.prev, .blog-pagination span.next {
  width: 75px;
}

@media only screen and (max-width: 992px) {
  .blog-pagination {
    margin: 20px auto 10px;
  }
}

.sidebar {
  margin-bottom: 90px;
  padding: 15px 20px;
}

.sidebar.sidebar-left {
  padding-left: 0;
}

.sidebar.sidebar-right {
  padding-right: 0;
}

@media only screen and (max-width: 992px) {
  .sidebar {
    margin-bottom: 15px;
    padding: 0 !important;
  }
}

.sidebar .widget {
  margin: 0 0 30px;
  padding: 25px 30px;
  border-radius: 0;
  background: #ffffff;
}

.sidebar .widget .widget-title {
  font-size: 20px;
  letter-spacing: .5px;
  line-height: 1.22727em;
  padding: 0;
  position: relative;
  margin: 0 0 20px;
  color: #1b1a1a;
}

.sidebar .widget .widget-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar .widget .widget-content ul li {
  margin: 0 0 10px;
  overflow: hidden;
}

.sidebar .widget .widget-content ul li a {
  color: #e9204f;
  font-weight: 400;
  margin-right: 10px;
  font-family: "Barlow", sans-serif;
  font-style: italic;
}

.sidebar .widget .widget-content ul li a:hover {
  color: #1b1a1a;
}

.sidebar .widget .tag-cloud-link {
  padding: 5px 12px;
  background-color: #f7f7f7;
  margin: 7px 5px;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Barlow", sans-serif;
  font-size: 13px;
  font-style: italic;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  box-shadow: 5px 5px 0 0 rgba(27, 26, 26, 0.2);
}

.sidebar .widget .tag-cloud-link:hover {
  background-color: #e9204f;
  color: #ffffff;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  box-shadow: 0 0 0 0 rgba(27, 26, 26, 0.2);
}

.sidebar .widget.social_media_widget ul li {
  display: inline-block;
  margin: 0 !important;
}

.sidebar .widget.social_media_widget ul li a {
  display: block;
  font-size: 18px;
  background-color: #f7f7f7;
  padding: 7px;
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 2px !important;
  color: #e9204f;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  box-shadow: 5px 5px 0 0 rgba(27, 26, 26, 0.2);
}

.sidebar .widget.social_media_widget ul li a:hover {
  background-color: #e9204f;
  color: #ffffff;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  box-shadow: 0 0 0 0 rgba(27, 26, 26, 0.2);
}

.sidebar .widget.widget_search {
  padding: 0;
  position: relative;
}

.sidebar .widget.widget_search label {
  width: 100%;
}

.sidebar .widget.widget_search .search-field {
  height: 50px;
  margin: 5px 0;
  border-radius: 0;
  font-size: 14px;
  text-transform: capitalize;
  padding: 15px 40px 15px 15px;
  outline: 0 !important;
  box-shadow: 0 0 0 !important;
  color: #e9204f;
  font-family: "Barlow", sans-serif;
  FONT-STYLE: italic;
  width: 100%;
  display: block;
}

.sidebar .widget.widget_search .search-submit {
  display: none;
}

.sidebar .widget.widget_search i {
  position: absolute;
  right: 15px;
  top: 22px;
  color: #1b1a1a;
}

.sidebar .widget.widget_categories ul li {
  position: relative;
}

.sidebar .widget.widget_categories ul li span {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  border: 1px solid #e9204f;
  width: 25px;
  height: 25px;
  line-height: 20px;
  text-align: center;
  color: #1b1a1a;
  font-family: "Barlow", sans-serif;
  font-style: italic;
}

.sidebar .widget .insta-photo {
  padding: 0;
  width: 75px;
  height: 75px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 4px 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  position: relative;
}

.sidebar .widget .insta-photo:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(27, 26, 26, 0.8);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.sidebar .widget .insta-photo a {
  display: flex;
  color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.sidebar .widget .insta-photo:hover:before {
  background-color: rgba(27, 26, 26, 0.4);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.sidebar .widget .insta-photo:hover a {
  color: #e9204f;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.sidebar .widget .news-img-list li {
  overflow: hidden;
  font-size: 12px;
  line-height: 22px;
  margin: 0 0 15px !important;
}

.sidebar .widget .news-img-list li .widget-news-item {
  display: flex;
}

.sidebar .widget .news-img-list li .widget-news-item a {
  display: inline-block;
  margin: 0;
}

.sidebar .widget .news-img-list li .widget-news-item .news-img {
  width: 60px;
  height: 60px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: solid 2px #e9204f;
}

.sidebar .widget .news-img-list li .widget-news-item .content {
  display: inline-block;
  width: calc(100% - 65px);
  padding-left: 10px;
}

.sidebar .widget .news-img-list li .widget-news-item .content .title a {
  font-size: 16px;
  line-height: 21px;
  font-family: "Barlow", sans-serif;
  color: #1b1a1a;
  font-weight: 500;
  text-transform: uppercase;
  font-style: normal;
  margin: 0;
}

.sidebar .widget .news-img-list li .widget-news-item .content .meta {
  margin-top: 4px;
}

.sidebar .widget .news-img-list li .widget-news-item .content .meta .author {
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
  font-style: italic;
  display: inline-block;
  margin: 0 !important;
}

.sidebar .widget .news-img-list li .widget-news-item .content .meta .date {
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
  font-style: italic;
  display: inline-block;
  margin: 0 !important;
  margin-left: 5px !important;
}

.sidebar .widget .news-list li {
  overflow: hidden;
  font-size: 12px;
  line-height: 22px;
  margin: 0 0 15px !important;
}

.sidebar .widget .news-list li .widget-news-item {
  display: flex;
}

.sidebar .widget .news-list li .widget-news-item a {
  display: inline-block;
  margin: 0;
}

.sidebar .widget .news-list li .widget-news-item .content {
  display: inline-block;
  width: 100%;
}

.sidebar .widget .news-list li .widget-news-item .content .title a {
  font-size: 16px;
  line-height: 21px;
  font-family: "Barlow", sans-serif;
  color: #1b1a1a;
  font-weight: 500;
  text-transform: uppercase;
  font-style: normal;
  margin: 0;
}

.sidebar .widget .news-list li .widget-news-item .content .meta {
  margin-top: 4px;
}

.sidebar .widget .news-list li .widget-news-item .content .meta .author {
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
  font-style: italic;
  display: inline-block;
  margin: 0 !important;
}

.sidebar .widget .news-list li .widget-news-item .content .meta .date {
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
  font-style: italic;
  display: inline-block;
  margin: 0 !important;
  margin-left: 5px !important;
}
